@import '../../styles/customMediaQueries.css';

.root {
}
.arabicDirection{
  direction: rtl;
  & .headingName{
    text-align: right !important;
  }
  & .mobileTopContent{
    & .backButton{
      & svg{
        transform: rotate(180deg);
      }
    }
  }
  & .contentHeading{
    text-align: right !important;
  }
  & .listingGallery{
    @media (max-width: 1800px) {
      padding-left: 0 !important;
      padding-right: 30px !important;
    }
  }
  & .arrow{
    right: unset !important;
    left: 0;
  }
  & .editIcon{
    margin-left: 8px !important;
  }
  & .navigationText{
    text-align: right !important;
  }
  .listingSections {
    @media (min-width: 1300px) {
      padding-right: 0 !important;
      padding-left: 70px !important;
    }
  }
}
.pageRoot {
  padding-bottom: 130px;
  background-color: #fff;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}
.reviewDetails{
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0 30px;
  @media (max-width: 767px) {
    margin: 0px 0 24px;
  }
  & svg{
    width: 24px;
    height: 24px;
    @media (max-width: 767px) {
      width: 18px;
      height: 18px;
    }
  }
  & span{
    font-family: Actor;
font-weight: 400;
font-size: 24px;
line-height: 34px;
letter-spacing: 0px;
color: #8A8A8F;
@media (max-width: 767px) {
  font-size: 20px;
line-height: 30px;
}
  }
}
.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}
.dealsItemWrapper{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin: 40px 0 60px 0;
  @media (max-width: 580px) {
    gap: 60px;
    grid-template-columns: 1fr;
  }
  & .dealItemModal{
    &>div>div{
      border-radius: 20px;
      @media (max-width: 1023px) {
        padding: 60px 16px;
        width: calc(100vw - 24px);
      }
    }
    &>div>div>div>div>div{
      &:nth-child(1){
        display: block !important; 
        &>div{
          min-height: 440px !important;
          background: #fff;
          border-radius: 50px;
          @media (max-width: 767px) {
            min-height: 480px !important;
          }
        }
      }
    }
  }
}
.priceContent{
  display: flex;
  gap: 16px;
  align-items: center;

}
.dealDiscount{
  &>div:nth-child(1){
    & span{
      color: #FF4E40;
    text-decoration: line-through;
    }
   }
}
.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.navigationText {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
  color: #000;
  margin: 0 0 20px;
  @media (max-width: 1023px) {
    display: none;
  }
}
.actionBarForProductLayout {
  margin: 24px auto;
  width: calc(100% - 48px);
  max-width: 1362px;
  @media (--viewportMedium) {
    margin: 24px auto;
  }

  @media (--viewportLarge) {
    margin: 24px auto 24px auto;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
    font-size: 21px;
    line-height: 30px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  min-height: auto;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  width: 59%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    width: 38%;
  }
}

.contentWrapperForProductLayout {
  gap: 45px;
  display: flex;
  margin-top: 85px;
  max-width: calc(1228px + 60px);
  padding: 0 30px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 48px;
  }
  @media (max-width: 767px) {
    margin-top: 16px;
  }
}
.mobileTopContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
  @media (min-width: 1024px) {
    display: none;
  }
  & .circleGray {
    height: 19px;
    width: 19px;
    background: #c8c7cc;
    border-radius: 100px;
  }
  & .rightIcons {
    display: flex;
    align-items: center;
    gap: 14px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  border: 1px solid #FFD046;
  border-radius: 30px;

  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
    border: 1px solid #FFD046;
    border-radius: 30px;
  }
  @media (max-width: 767px) {
    margin-bottom: 140px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0px;
  margin-bottom: 36px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  @media (max-width: 1023px) {
    font-family: SF Pro Text;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}
.sliderRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #6C7275;
  margin-bottom: 16px;
  padding-bottom: 10px;
  margin-top: 40px;

  & h2{
    margin: 0;
    padding: 0;
    padding: 0;
    font-family: Actor;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: -0.4px;
    
  }
  & .viewAll{
    cursor: pointer;
    margin-left: auto;
    width: max-content;
    text-decoration: underline;

    &:hover{
      color: #000;
    }
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 0px;
  margin-top: 42px;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }

  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  min-height: auto;
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 35px 0;

  @media (--viewportMedium) {
    margin: 24px 0 45px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 85px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.listingWrapper {
  width: 100%;
  margin: 0px auto;
  padding: 40px 0;
  background: #fff;
  position: relative;
  max-width: calc(1365px);
}

.listingTopHeading {
  max-width: calc(1365px + 60px);
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
  & .listingHeading {
    font-size: 48px;
    font-weight: 400;
    color: #272728;
    line-height: 51px;
    margin-bottom: 12px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
  & .subTitleMobile {
     font-family: "Montserrat";
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000;
    margin: 0 0 24px;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  & .reviewStars {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    padding: 16px 0;
    @media (max-width: 767px) {
      padding: 4px 0;
    }
    & svg {
      margin-right: 2px;
      @media (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }

    & .ratingCount {
      font-size: 13px;
      font-weight: 400;
      color: #8a8a8f;
      line-height: 18px;
      margin-left: 10px;
      @media (max-width: 767px) {
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}

.listingGallery {
  width: 100%;
  position: relative;
  max-width: 100vw;
  @media (max-width: 1800px) {
    max-width: calc(100vw - 50px);
    padding-left: 30px;
  }
  @media (max-width: 1023px) {
    /* display: none; */
    padding-left: 16px;
  }
  @media (max-width: 767px) {
    max-width: calc(100vw - 42px);
  }

  & .galleryImage {
    & img {
      width: 100%;
    }
  }
}
.listingGalleryMobile {
  display: block;
  & .galleryMobile {
     font-family: "Montserrat";
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #000;
    margin: 27px 0 19px;
    @media (min-width: 1024px) {
      display: none;
    }
  }
}
.listingSections {
  @media (min-width: 1300px) {
    padding-right: 70px;
  }
  @media (max-width: 1023px) {
    /* display: none; */
  }
  & .headingName {
    font-size: 18px;
    color: #141718;
    font-weight: 500;
    line-height: 32px;
    padding-bottom: 8px;
    margin-bottom: 20px;
    border-bottom: 1px solid #6c7275;
    letter-spacing: -0.4000000059604645px;
    @media (max-width: 1023px) {
      font-size: 17px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      margin-bottom: 47px;
      padding-bottom: 0;
      border-bottom: unset;
    }
  }
}
.listingSectionsMobile {
  display: block;
}
.accordionSections {
  margin-bottom: 24px;

  & .headingName {
    cursor: pointer;
    font-size: 18px;
    color: #141718;
    font-weight: 500;
    line-height: 32px;
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid #6c7275;
    letter-spacing: -0.4000000059604645px;

    & .arrow {
      right: 0;
      top: 50%;
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);

      & svg {
        width: 24px;
        height: 24px;

        & path {
          &:last-child {
            stroke: #141718;
          }
        }
      }
    }
  }

  & .categoryLevelList {
    & .categoryLevelName {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.contentHeading {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #575758;
  font-weight: 400;
  line-height: 26px;
}

.priceContainer {
  & .price {
    font-size: 20px;
    color: #0e0e10;
    font-weight: 500;
    line-height: 30px;
  }

  & .perUnit {
  }
}

.detailValue {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 15px;
}

.detailLabelName {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.accordionList {
  width: 60%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.desktopDescription {
  @media (max-width: 1023px) {
    display: none;
  }
}
.mobileDescription {
  @media (min-width: 1024px) {
    display: none;
  }
}
.listingMobileInformation {
  display: block !important;
}

.relatedListContainer{
  /* display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px; */
  position: relative;
  @media (max-width: 1023px) {
    width: calc(100vw - 32px);
  }
  & :global(.slick-slide){
    &>div{
      margin: 0 10px;
    }
  }
  & :global(.slick-arrow){
    &::before{
      display: none;
    }
  }
  & :global(.slick-arrow){
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ddd;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  & :global(.slick-next){
    right: 20px !important;
  }
  & :global(.slick-disabled){
    opacity: 0.4 !important;
  }
  & :global(.slick-prev) {
    left: 20px;
    z-index: 1;
  }
}
